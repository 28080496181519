const ko = {
    a1: "눈 점수",
    a2: "하위",
    a2_: "하위 ",
    a3: "상위",
    a3_: "상위 ",
    a4: "당신은 앱 사용자 기준 ",
    a4_: "당신은 앱 사용자 기준 ",
    a5: "에 속합니다.",
    a6: "사용자 평균 점수",
    a7: "나의 최근 점수",
    a8: "누적현황",
    a9: "단위",
    a10: "점",
    a101: "점",
    a11: "우안",
    a12: "좌안",
    a13: "양안" ,
    a14: "최초",
    a15: "최근",
    a16: "회",
    a17: "눈 초점 반응 속도",
    a18: "사용자 평균 속도",
    a19: "나의 최근 속도",
    a20: "초",
    a21: "눈 운동 게임 결과",
    a22: "표준이하",
    a23: "표준",
    a24: "표준이상",
    a25: "시각주시력",
    a26: "시각인지력",
    a27: "시각집중력",
    a28:"시각기억력",
    a29: "주변시운동",
    a30: "상위90%란, 오투스APP사용자 100명을 기준으로 순위 90번째를 의미합니다.",
    a31: "눈 점수는 디바이스와 앱 연동으로 측정된 내 눈상태를 수치화 한 점수로 200점이 최고 점수이며 최소 점수는 10점 입니다. 점수가 높을수록 눈 상태가 좋음을 의미 합니다.",
    a32: "초점반응속도는 수정체 근육이 초점을 맺는 속도를 측정한 것으로 반응시간(초) 단위로 표시되며, 수치가 적을수록 눈의 초점이 빠르게 반응한다는 뜻이므로 수치가 낮을수록 눈 상태가 좋음을 의미 합니다.",
    a33: "· 상위90%란, 오투스APP사용자 100명을 기준으로 순위 90번째를 의미합니다."
}
export default ko;