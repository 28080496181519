import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import JsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import $ from 'jquery';
const options = {
  orientation: 'portrait',
  unit: 'mm',
  format: [420, 594]
};
const pdf = new JsPdf(options);


function saveAs(uri, filename) {

  var link = document.createElement('a');

  if (typeof link.download === 'string') {

    link.href = uri;
    link.download = filename;

    //Firefox requires the link to be in the body
    document.body.appendChild(link);

    //simulate click
    link.click();

    //remove the link when done
    document.body.removeChild(link);

  } else {

    window.open(uri);

  }
}

class ReactToPdf extends PureComponent {
  constructor(props) {
    super(props);
    this.targetRef = React.createRef();
    this.state = {
      index: 0
    }
    this.drawNaddToPdf = this.drawNaddToPdf.bind(this);

    setTimeout(() => this.drawNaddToPdf(), 2000)
  }

  drawNaddToPdf() {
    const { targetRef, filename, x, y, onComplete, fileType } = this.props;
    if (!targetRef.current) {
      throw new Error(
        'Target ref must be used or informed.'
      );
    }
    console.log("targetRef", targetRef.current)
    const scale = 2;
    domtoimage.toJpeg(targetRef.current,  {
      width: targetRef.current.clientWidth * scale,
      height: targetRef.current.clientHeight * scale,
      style: {
       transform: 'scale('+scale+')',
       transformOrigin: 'top left'
     }
    })
      .then(function (dataUrl) {
        // var img = new Image();
        // img.src = dataUrl;
        if (fileType === "jpeg") {
          saveAs(dataUrl, filename + ".jpeg")
          if (onComplete) onComplete();
        } else {
          pdf.addImage(dataUrl, 'JPEG', x, y);

          pdf.save(filename);
          if (onComplete) onComplete();

        }
        //document.body.appendChild(img);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });

    // html2canvas(targetComponent, {
    //   logging: false,
    //   useCORS: true,
    //   scale: 2,
    //   dpi: 144,
    //   x: (window.innerWidth / 2) - ($(targetComponent).width() / 2)
    // }).then(canvas => {
    //   const imgData = canvas.toDataURL('image/jpeg');
    //   if (fileType === "jpeg") {
    //     saveAs(imgData, filename + ".jpeg")
    //   } else {
    //     pdf.addImage(imgData, 'JPEG', x, y);
    //     this.setState({ index: (this.state.index + 1) });
    //     if (this.state.index < source.length) {
    //       pdf.addPage();
    //       pdf.setPage(pdf.getCurrentPageInfo().pageNumber + 1);
    //       this.drawNaddToPdf();
    //     } else {
    //       this.setState({ index: 0 })
    //       pdf.save(filename);
    //       if (onComplete) onComplete();
    //     }
    //   }
    // });
  }

  render() {
    const { children } = this.props;
    return children({ drawNaddToPdf: this.drawNaddToPdf, targetRef: this.targetRef });
  }
}

ReactToPdf.propTypes = {
  filename: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  options: PropTypes.shape({}),
  scale: PropTypes.number,
  children: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  targetRef: PropTypes.oneOfType([
    PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) }, PropTypes.array)
  ])
};

ReactToPdf.defaultProps = {
  filename: 'download.pdf',
  options: undefined,
  x: 0,
  y: 0,
  scale: 1,
  onComplete: undefined,
  targetRef: undefined
};

export default ReactToPdf;
