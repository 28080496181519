const tw = {
    a1: "眼睛分數",
    a2: "後",
    a2_: "只超過奧圖視",
    a3: "前",
    a3_: "超越了",
    a4: "你的視力分數",
    a4_: "你的調節反應速度",
    a5: "的Otus用戶",
    a6: "用戶平均分數",
    a7: "我的最近分數",
    a8: "累計數據",
    a9: "單位",
    a10: "分",
    a101: "分",
    a11: "右眼",
    a12: "左眼",
    a13: "雙眼" ,
    a14: "最初",
    a15: "最近",
    a16: "次",
    a17: "調節反應速度",
    a18: "用戶平均速度",
    a19: "我的最近速度",
    a20: "秒",
    a21: "遊戲訓練結果",
    a22: "標準以下",
    a23: "標準",
    a24: "標準以上",
    a25: "視覺注視力 ",
    a26: "視覺認知",
    a27: "視覺集中力",
    a28:"視覺記憶力",
    a29: "周邊注視運動",
    a30: "前90%是指，100名奧圖視用戶中排名第90名。數值越小越靠前。",
    a31: "眼睛分數是對用OTUS APP檢測的用戶的眼睛狀況進行量化的。最高分數爲200分，最低爲10分。分數越高，代表眼睛越健康。",
    a32: "調節反應速度是，眼睛看遠之後能很快看清楚近處，看近之後能很快看清楚遠處的調節能力也就是聚焦能力的測定數值，單位爲“秒”。調節反應也是評價眼睛是否能夠平穩有效地改變調節量的指標。測定數值越小，表示調節反應越快，眼睛越健康。調節反應速度是在測定訓練強度時被測定。",
    a33: "前90%是指，100名奧圖視用戶中排名第90名。數值越小越靠前。"
}
export default tw;