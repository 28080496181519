import axios from "axios";
import { getFormatedDate } from "../utils/utils";

//const backServer = "http://localhost:8080";
var address = "https://cn.edenlux.cn:";
var backServer = "";

export function setBackServer(port){
   backServer = address+port;
   console.log("backServer set: "+backServer)
}


export function svcGetUserInfo(value, token) {
    let data = JSON.stringify({
        uid: value,
    });
    console.log("get user info for: "+value)
    return new Promise(function (resolve, reject) {
        axios.post(backServer + "/getuserinfo",
            data,
            { headers: { 'Content-Type': 'application/json', "Authorization": token, "charset" : "UTF-8" } })
            .then(response => {
                 console.log("rep data"+response.data);
                if (response.data.status === "fail") {
                    reject();
                } else {
                    resolve(response.data);
                }
            })
            .catch(error => {
                console.log("UserInfo Rejected: " + error);
                reject();
            });
    });
}

export function svcGetThiMonthGameCount(uid, token) {

    let data = JSON.stringify({
        uid: uid,
        date: getFormatedDate(new Date(), true)+"%"
    });
    return new Promise(function (resolve, reject) {
        axios.post(backServer + "/getgamecount",
            data,
            { headers: { 'Content-Type': 'application/json', "Authorization": token, "charset" : "UTF-8" } })
            .then(response => {
                if (response.data.status === "fail") {
                    reject();
                } else {
                    resolve(response.data);
                }
            })
            .catch(error => {
                reject();
            });
    });
}

export function svcGetVA(uid, token) {

    let data = JSON.stringify({
        uid: uid,
    });
    return new Promise(function (resolve, reject) {
        axios.post(backServer + "/getva",
            data,
            { headers: { 'Content-Type': 'application/json', "Authorization": token, "charset" : "UTF-8" } })
            .then(response => {
                if (response.data.status === "fail") {
                    reject();
                } else {
                    resolve(response.data);
                }
            })
            .catch(error => {
                reject();
            });
    });
}

export function svcGetIntensity(uid, token) {

    let data = JSON.stringify({
        uid: uid,
    });
    return new Promise(function (resolve, reject) {
        axios.post(backServer + "/getintensity",
            data,
            { headers: { 'Content-Type': 'application/json', "Authorization": token, "charset" : "UTF-8" } })
            .then(response => {
                if (response.data.status === "fail") {
                    reject();
                } else {
                    resolve(response.data);
                }
            })
            .catch(error => {
                reject();
            });
    });
}

export function svcGetGameData(uid, gameId, token) {

    let data = JSON.stringify({
        uid: uid,
        gameId: gameId
    });
    return new Promise(function (resolve, reject) {
        axios.post(backServer + "/getgame",
            data,
            { headers: { 'Content-Type': 'application/json', "Authorization": token, "charset" : "UTF-8" } })
            .then(response => {
              //  console.log(response.data);
                if (response.data.status === "fail") {
                    reject();
                } else {
                    resolve(response.data);
                }
            })
            .catch(error => {
                //console.log("GameData Rejected: " + error);
                reject();
            });
    });
}

export function svcGetUsersVA(token) {

    return new Promise(function (resolve, reject) {
        axios.get(backServer + "/getallva", {headers: { 'Content-Type': 'application/json', "Authorization": token, "charset" : "UTF-8" }})
            .then(response => {
                if (response.data.status === "fail") {
                    reject();
                } else {
                    resolve(response.data);
                }
            })
            .catch(error => {
                //console.log("GameData Rejected: " + error);
                reject();
            });
    });
}
export function svcGetUsersIntensity(token) {

    return new Promise(function (resolve, reject) {
        axios.get(backServer + "/getallintensity", {headers: { 'Content-Type': 'application/json', "Authorization": token, "charset" : "UTF-8" }})
            .then(response => {
                if (response.data.status === "fail") {
                    reject();
                } else {
                    resolve(response.data);
                }
            })
            .catch(error => {
                //console.log("GameData Rejected: " + error);
                reject();
            });
    });
}

export function svcGetAgeUids(value, token) {

    let data = JSON.stringify({
        birthYear: value
    });
    return new Promise(function (resolve, reject) {
        axios.post(backServer + "/getageuids",
            data,
            { headers: { 'Content-Type': 'application/json', "Authorization": token, "charset" : "UTF-8" } })
            .then(response => {
                //console.log(response.data);
                if (response.data.status === "fail") {
                    reject();
                } else {
                    resolve(response.data);
                }
            })
            .catch(error => {
              //  console.log("GameData Rejected: " + error);
                reject();
            });
    });
}

export function formatDate(date, fullYear, separator) {
    if(!separator) separator = ".";
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (!fullYear) year = year.toString().substring(2, 4);

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join(separator);
}

export function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
}

export function getLastDate(date) {
    var d = new Date(date);
    var lastD = daysInMonth(d.getMonth() + 1, d.getFullYear());
    d.setDate(lastD);
    return formatDate(d, true);
}

export function getInternationAge(date) {
  // Date 객체인 birthday 를 매개변수로 받는 getWesternAge 함수 생성
  var d = new Date();
  var birthYear = parseInt(date.split("/")[0]);
  let internationAge = d.getFullYear() - birthYear;
  // 현재 연도에서 생일 연도를 뺀 값을 age 로 한다.
  let birthMonth = parseInt(date.split("/")[1]);
  let thisMonth = d.getMonth();
  // 현재 월과 생일 월을 변수에 저장한다.
  let birthDate = parseInt(date.split("/")[2]);
  let thisDate = d.getDate();
  // 현재 날짜와 생일 날짜를 변수에 저장한다.
  // 아래 코드는 다음 흐름에 따라 작성되었다.
  // 1. 생일 월과 현재 월을 비교 (3가지 경우의 수를 if, else if, else 로 처리)
  // 2. 생일 월과 현재 월이 같을 경우, 날짜를 비교
  if (birthMonth < thisMonth) {
    return internationAge;
  } else if (birthMonth > thisMonth) {
    return internationAge -1;
  } else {
    if (birthDate < thisDate) {
      return internationAge;
    } else {
      return internationAge -1;
    }
  }
}

export function getAge(date) {
    var d = new Date();

    var birthYear = parseInt(date.split("/")[0]);
    return d.getFullYear() - (birthYear - 1);
}

export function getMonth(date, separator) {
    if (!separator) separator = "-";
    return parseInt(date.split(separator)[1]);
}
export function getDailyScores(gameDatas) {
    var scores = [];
    var d = new Date();
    d.setDate(1);
    d.setMonth(d.getMonth() - 1);


    var idxs = getMnthIdxes(gameDatas, (d.getMonth() + 1));
    var numOfDays = daysInMonth(d.getMonth() + 1, d.getFullYear());
    for (var i = 0; i < numOfDays; i++) {
        var today = formatDate(d, true, "-");
        //console.log("Today: "+today);
        var score = (GetEScore(gameDatas, -1, 0, today, idxs[0], idxs[1]) + GetEScore(gameDatas, -1, 1, today, idxs[0], idxs[1]) + GetEScore(gameDatas, -1, 2, today, idxs[0], idxs[1])) / 3;
      //  console.log("date: "+today+" score: "+score+" idx"+idxs[0]+" "+idxs[1]);
        if(score<0) score = 0;
        scores.push(Math.round(score));
        d.setDate(d.getDate() + 1);
    }
    return scores;
}

export function getMonthScore(gameDatas, mthIdx) {
    var d = new Date();
    d.setDate(1);
    d.setMonth(d.getMonth() - 1);
    var date = new Date(d.setMonth(d.getMonth() + mthIdx));
    // if(mthIdx === -2)
    //    console.log(date.getMonth()+" "+date.getDate());
    var index = 0;
    var score = 0;
    var numOfDays = daysInMonth(date.getMonth() + 1, date.getFullYear());
    var idxs = getMnthIdxes(gameDatas, date.getMonth() + 1);
    //console.log("idx1: "+idxs[0]+" idx2: "+idxs[1]+ "Month: "+(date.getMonth() + 1)+" nod: "+numOfDays);
    for (var j = 0; j < numOfDays; j++) {
        var today = formatDate(date, true, "-");
        //console.log("Today: "+today);
        var tempScore1 = GetEScore(gameDatas, -1, 0, today, idxs[0], idxs[1]);
        var tempScore2 = GetEScore(gameDatas, -1, 1, today, idxs[0], idxs[1]);
        var tempScore3 = GetEScore(gameDatas, -1, 2, today, idxs[0], idxs[1]);

        var tempScores = [];
        if(tempScore1 !== -1) tempScores.push(tempScore1);
        if(tempScore2 !== -1) tempScores.push(tempScore2);
        if(tempScore3 !== -1) tempScores.push(tempScore3);
        var tempScore = 0;
        for(var i=0; i<tempScores.length; i++){
            tempScore+= tempScores[i];
        }

        if(tempScore > 0){
            score+= (tempScore/tempScores.length);
            index+=1;
        }
        date.setDate(date.getDate()+1);
    }
    if (index === 0) return 0;
    return Math.round(score / index);
}

export function getTypeScore(gameDatas, distLength, side, gameId) {
    var date = new Date();
    date.setDate(1);
    date.setMonth(date.getMonth() - 1);

    var index = 0;
    var score = 0;
    var idxs = getMnthIdxes(gameDatas, date.getMonth() + 1);
    var numOfDays = daysInMonth(date.getMonth() + 1, date.getFullYear());
    for (var j = 0; j < numOfDays; j++) {
        var today = formatDate(date, true, "-");

        var tempScore = GetEScore(gameDatas, distLength, side, today, idxs[0], idxs[1], gameId);
        if(tempScore !== -1){
            score+= tempScore;
            index+=1;
        }
        date.setDate(date.getDate()+1);
    }

    if (index === 0) return 0;
    return Math.round(score / index);
}
export function getGameScore(gameDatas, gameId){
  var date = new Date();
  date.setDate(1);
  date.setMonth(date.getMonth() - 1);

  var score = 0;
  var count = 0;
  var idxs = getMnthIdxes(gameDatas, date.getMonth() + 1);
  var numOfDays = daysInMonth(date.getMonth() + 1, date.getFullYear());
  for (var i=idxs[0]; i<idxs[1]; i++){
      if(gameDatas[i].gameId !== gameId) continue;
      score += gameDatas[i].score;
      count+=1;
  }

  if (count === 0) return 0;
  return Math.round(score / count);
}

export function getMnthIdxes(gameDatas, month) {
    var fstIdx = -1, lstIdx = -1;
    for (var j = 0; j < gameDatas.length; j++) {
        if (fstIdx === -1 && getMonth(gameDatas[j].date) === (month)) {
            fstIdx = j;
        }
        if (fstIdx !== -1) {
            if (getMonth(gameDatas[j].date) !== (month)) {
                return [fstIdx, j - 1];
            }
            else
                lstIdx = j;
        }
    }
    return [fstIdx, lstIdx];
}
export function getDayCount(gameDatas, month) {
    var monthCount = 0;
    var attendedDays = [];
    if(gameDatas)
    for (var i = 0; i< gameDatas.length; i++)
    {
        var gd = gameDatas[i];
        if(attendedDays.includes(gd.date)) continue;
        var dayCount = 1;
        for(var j = i+1; j<gameDatas.length; j++){
            if(gameDatas[j].date === gd.date){
                dayCount++;
            }
        }
        if(dayCount < 3) continue;

        attendedDays.push(gd.date);
        if(getMonth(gd.date) === month){
            monthCount++;
        }
    }
    return monthCount;
}

export function getMyDistance(gameDatas) {
    var distTotal = 0;
    var date = new Date();

    date.setDate(1);
    date.setMonth(date.getMonth() - 1);
    var daysCount = daysInMonth(date.getMonth(), date.getFullYear());
    date.setDate(daysCount);
    var idxs = getMnthIdxes(gameDatas, date.getMonth() + 1);
    if (!gameDatas) return 0;
    var dataCount = 0;
    for (var i = idxs[0]; i <= idxs[1]; i++) {
        var gd = gameDatas[i];
        if(!gd) continue;
        if (gd.distR > 45) gd.distR = 45;
        else if (gd.distR < 25) gd.distR = 25;
        if (gd.distL > 45) gd.distL = 45;
        else if (gd.distL < 25) gd.distL = 25;
        if (gd.distB > 45) gd.distB = 45;
        else if (gd.distB < 25) gd.distB = 25;
        distTotal += (gd.distR + gd.distL + gd.distB);
        dataCount += 1;
    }
    if (dataCount === 0) return 0;
    return Math.round(distTotal / (3 * dataCount));
}

export function GetEScore(gameDatas, distLength, side, date, fstIdx, lstIdx, gameId) {
  //console.log("getescore: "+date);
    var reactTAverage, ansRatioAver, idxs;
    if(!fstIdx || !lstIdx){
        var _date = new Date();
        _date.setDate(1);
        _date.setMonth(_date.getMonth() - 1);
       idxs = getMnthIdxes(gameDatas, (_date).getMonth()+1);
       fstIdx = idxs[0];
       lstIdx = idxs[1];
    }

    var cpm, escore, dataCount = 0;

    if (gameDatas && gameDatas.length > 0) {

        var reactTimeTotal = 0, distTotal = 0, ansRatioTotal = 0;
      //  console.log("1: "+fstIdx+" 2: "+lstIdx);
        for (var i = fstIdx; i < lstIdx; i++) {
            var gd = gameDatas[i];
            //console.log("Game date: "+gd.date);
            if (date && date !== gd.date){
              //console.log("blocked at date: "+gd.date+ " vs "+date+" 1idx: "+fstIdx+ " 2idx "+lstIdx);
              continue;
            }
          //  console.log("passed date: "+gd.date);
            if(gameId && gameId !== gd.gameId){
              //console.log("blocked gameId: "+gameId);
               continue;
             }
            //console.log("passed gameId: "+gameId);
            switch (side) {
                case 0: //right
                    if (distLength === -1) {
                        if(parseFloat(gd.set1ReactTimeR) > 20 || parseFloat(gd.set2ReactTimeR) > 20){
                            continue;
                        }
                        reactTimeTotal += (parseFloat(gd.set1ReactTimeR) + parseFloat(gd.set2ReactTimeR));
                        distTotal += gd.distR;
                        ansRatioTotal += (parseFloat(gd.set1RightAnsRatio) + parseFloat(gd.set2RightAnsRatio));
                        dataCount+=1;
                    } else {
                        if (GetVisionLength(gd.lensSet1R) === distLength) {
                            if(parseFloat(gd.set1ReactTimeR) > 20){
                                continue;
                            }
                            reactTimeTotal += parseFloat(gd.set1ReactTimeR);
                            distTotal += gd.distR;
                            ansRatioTotal += parseFloat(gd.set1RightAnsRatio);
                            dataCount+=1;
                        }
                        if (GetVisionLength(GetLens2(gd.lensSet1R)) === distLength) {
                            if(parseFloat(gd.set2ReactTimeR) > 20){
                               continue;
                            }
                            reactTimeTotal += parseFloat(gd.set2ReactTimeR);
                            distTotal += gd.distR;
                            ansRatioTotal += parseFloat(gd.set2RightAnsRatio);
                            dataCount+=1;
                        }
                    }
                    break;
                case 1: //left
                    if (distLength === -1) {
                        if(parseFloat(gd.set1ReactTimeL) > 20 || parseFloat(gd.set2ReactTimeL) > 20){
                            continue;
                        }
                        reactTimeTotal += (parseFloat(gd.set1ReactTimeL) + parseFloat(gd.set2ReactTimeL));
                        distTotal += gd.distL;
                        ansRatioTotal += (parseFloat(gd.set1LeftAnsRatio) + parseFloat(gd.set2LeftAnsRatio));;
                        dataCount+=1;
                    } else {

                        if (GetVisionLength(gd.lensSet1L) === distLength) {
                            if(parseFloat(gd.set1ReactTimeL) > 20){
                                continue;
                            }
                            reactTimeTotal += parseFloat(gd.set1ReactTimeL);
                            distTotal += gd.distL;
                            ansRatioTotal += parseFloat(gd.set1LeftAnsRatio);
                            dataCount+=1;
                        }
                        if (GetVisionLength(GetLens2(gd.lensSet1L)) === distLength) {
                            if(parseFloat(gd.set2ReactTimeL) > 20){
                                 continue;
                            }
                            reactTimeTotal += parseFloat(gd.set2ReactTimeL);
                            distTotal += gd.distL;
                            ansRatioTotal += parseFloat(gd.set2LeftAnsRatio);
                            dataCount+=1;
                        }
                    }
                    break;
                case 2: //both
                    if (distLength === -1) {
                         if(parseFloat(gd.set1ReactTimeB) > 20 || parseFloat(gd.set2ReactTimeB) > 20){
                            continue;
                         }
                        reactTimeTotal += (parseFloat(gd.set1ReactTimeB) + parseFloat(gd.set2ReactTimeB));
                        distTotal += gd.distB;
                        ansRatioTotal += (parseFloat(gd.set1BothAnsRatio) + parseFloat(gd.set2BothAnsRatio));
                        dataCount+=1;
                    } else {
                        if (GetVisionLength(gd.lensSet1B) === distLength) {
                            if(parseFloat(gd.set1ReactTimeB) > 20){
                                continue;
                            }
                            reactTimeTotal += parseFloat(gd.set1ReactTimeB);
                            distTotal += gd.distB;
                            ansRatioTotal += parseFloat(gd.set1BothAnsRatio);
                            dataCount+=1;
                        }
                        if (GetVisionLength(GetLens2(gd.lensSet1B)) === distLength) {
                            if(parseFloat(gd.set2ReactTimeB) > 20){
                                continue;
                            }
                            reactTimeTotal += parseFloat(gd.set2ReactTimeB);
                            distTotal += gd.distB;
                            ansRatioTotal += parseFloat(gd.set2BothAnsRatio);
                            dataCount+=1;
                        }
                    }
                    break;
            }

        }

        if (dataCount === 0) return -1;
        reactTAverage = reactTimeTotal / dataCount;
        ansRatioAver = ansRatioTotal / dataCount;
        cpm = (60 / (reactTAverage / ansRatioAver));
        if (cpm > 60) cpm = 60;
        else if (cpm < 1) cpm = 1;
        escore = (cpm / 60) * 100;
        if (escore > 100) escore = 100;
        else if (escore < 0) escore = 0;
        return Math.round(escore);
    } else
        return -1;
}



export function GetLens2(_lens1) {
    return _lens1.substring(1, 2) + _lens1.substring(0, 1);
}
export function GetVisionLength(lensSet) {
    switch (lensSet) {
        //초근거리
        case "43":
        case "53":
        case "23":
            return 0;

        //중거리
        case "25":
        case "45":
        case "35":
            return 2;

        //근거리
        case "54":
        case "24":
        case "34":
            return 1;

        //원거리
        case "52":
        case "42":
        case "32":
            return 3;

        default:
            return -1;
    }
}
export function getGraphIndex(value) {
    value = value / 10;
    return Math.round(value);
}



export function GetVisualAccuity(gameDatas, side, fstIdx, lstIdx) {
    //console.log("VA: "+fstIdx+" "+lstIdx);
    var idxs, dataCount = 0;
    var vaLens = ["52", "42", "32"];
    var vaGames = [1, 3, 4, 7];
    var vaSizes = [0.54694, 0.27347, 0.18231, 0.10939, 0.07813, 0.06837, 0.06077, 0.05469, 0.04558];
    if(!fstIdx || !lstIdx){
       var date = new Date();
       date.setMonth(date.getMonth() - 1);
       idxs = getMnthIdxes(gameDatas, (date).getMonth()+1);
       fstIdx = idxs[0];
       lstIdx = idxs[1];
    }

    if (gameDatas && gameDatas.length > 0) {
        var va = 0;
        for (var i = fstIdx; i < lstIdx; i++) {
            var gd = gameDatas[i];
            if(!vaGames.includes(gd.gameId)) continue;
            switch (side) {
                case 0: //right
                if (vaLens.includes(gd.lensSet1R)) {
                    va += (gd.distR * 10 * 0.0002917 * 5)/(vaSizes[gd.level-1]*10);
                    dataCount+=1;
                }
                if (vaLens.includes(GetLens2(gd.lensSet1R))) {
                    va += (gd.distR * 10 * 0.0002917 * 5)/(vaSizes[gd.level-1]*10);
                    dataCount+=1;
                }
                    break;
                case 1: //left
                if (vaLens.includes(gd.lensSet1L)) {
                    va += (gd.distL * 10 * 0.0002917 * 5)/(vaSizes[gd.level-1]*10);
                    dataCount+=1;
                }
                if (vaLens.includes(GetLens2(gd.lensSet1L))) {
                    va += (gd.distL * 10 * 0.0002917 * 5)/(vaSizes[gd.level-1]*10);
                    dataCount+=1;
                }
                    break;
                case 2: //both
                if (vaLens.includes(gd.lensSet1B)) {
                    va += (gd.distB * 10 * 0.0002917 * 5)/(vaSizes[gd.level-1]*10);
                    dataCount+=1;
                }
                if (vaLens.includes(GetLens2(gd.lensSet1B))) {
                    va += (gd.distB * 10 * 0.0002917 * 5)/(vaSizes[gd.level-1]*10);
                    dataCount+=1;
                }
                    break;
            }

        }

        if (dataCount === 0) return -1;
        va = va / dataCount;

        return Math.round(va*10)/10;
    } else
        return -1;
}
function isSameAge(uids, uid){
    for(var i=0; i<uids.length; i++){
        if(uids[i].uid === uid) return true;
    }
    return false;
}

export function GetAllCPM(gameDatas, distLength, side, gameId, uids) {
    var reactTAverage, ansRatioAver;

    var cpm, escore, dataCount = 0;
    if (gameDatas && gameDatas.length > 0) {

        var reactTimeTotal = 0, distTotal = 0, ansRatioTotal = 0;

        for (var i = 0; i < gameDatas.length; i++) {
            var gd = gameDatas[i];
            if(gameId && gameId !== gd.gameId){
                 continue;
            }
            if(uids && !isSameAge(uids, gd.uid)){
                continue;
            }

            if(parseFloat(gd.set1ReactTimeB) > 20 || parseFloat(gd.set2ReactTimeB) > 20){
                  continue;
            }
            var currentReactTime = parseFloat(gd.set1ReactTimeB) + parseFloat(gd.set2ReactTimeB);
            var currentAnsRatio = parseFloat(gd.set1BothAnsRatio) + parseFloat(gd.set2BothAnsRatio);

            if(currentReactTime <= 0 || currentReactTime > 10) continue;
            if(currentAnsRatio <= 0 || currentReactTime > 10) continue;

            reactTimeTotal += currentReactTime;
            ansRatioTotal += currentAnsRatio;
            dataCount+=1;
            if(dataCount > 1){
                  ansRatioTotal /= 2;
                  reactTimeTotal /= 2;
            }

        }

        if (dataCount === 0) return -1;
        cpm = (60 / (reactTimeTotal / ansRatioTotal));
        if (cpm > 60) cpm = 60;
        else if (cpm < 1) cpm = 1;
        escore = (cpm / 60) * 100;
        if (escore > 100) escore = 100;
        else if (escore < 0) escore = 0;
        return Math.round(escore);
    } else
        return -1;
}
export function getAllGame(gameDatas, gameId, uids){
  var score = 0, dataCount = 0;
  if (gameDatas && gameDatas.length > 0) {

      for (var i = 0; i < gameDatas.length; i++) {
          var gd = gameDatas[i];
          if(gameId && gameId !== gd.gameId){
               continue;
          }

          score+=parseInt(gd.score);
          dataCount+=1;
      }
      if (dataCount === 0) return 0;

      return Math.round(score/dataCount);
  } else
      return 0;
}
