const ko = {
    a1: "目のスコア",
    a2: "下位",
    a2_: "下位",
    a3: "上位",
    a3_: "上位",
    a4: "あなたはアプリユーザー基準",
    a4_ : "あなたはアプリユーザー基準",
    a5: "です。",
    a6: "ユーザー平均スコア",
    a7: "私の最近のスコア",
    a8: "累積現況",
    a9: "単位",
    a10: "点",
    a101: "点",
    a11: "右眼",
    a12: "左眼",
    a13: "両眼" ,
    a14: "初回",
    a15: "最近",
    a16: "回",
    a17: "目焦点反応速度",
    a18: "ユーザー平均速度",
    a19: "私の最近の速度",
    a20: "秒",
    a21: "目の運動ゲームの結果",
    a22: "標準以下",
    a23: "標準",
    a24: "標準以上",
    a25: "視覚注視力",
    a26: "視覚認知力",
    a27: "視覚集中力",
    a28: "視覚記憶力",
    a29: "周辺視運動",
    a30: "上位90%とは、Otusアプリユーザー中100人を基準にした90番目の順位を意味します。",
    a31: "目のスコアはデバイスとアプリの連動で測定された自分の目の状態を数値化したスコアで、200点が最高スコアで最小スコアは10点です。スコアが高いほど目の状態が良いことを意味します。",
    a32: "焦点反応速度は水晶体の筋肉が焦点を合わせる速度を測定したもので、反応時間(秒)単位で表示され、数値が低いほど目の焦点が速く反応するという意味なので、数値が低いほど目の状態が良いことを意味します。焦点反応速度は訓練強度測定時の測定となります。",
    a33: "上位90%とは、Otusアプリユーザー中100人を基準にした90番目の順位を意味します。"
}
export default ko;