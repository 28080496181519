import ko from '../languages/ko'
import en from '../languages/en.json'
import cn from '../languages/cn'
import tw from '../languages/tw'
import jp from '../languages/jp'

const diseases_kr = ["정상", "근시", "원시", "난시", "노안", "약시", "사시", "사위"],
    diseases_en = [
        "Emmetropia",
        "Nearsighted",
        "Farsighted",
        "Astigmatism",
        "Presbyopia",
        "Amblyopia",
        "Cross-eye",
        "Phoria"
    ],

    diseases_twn = [
        "正常",
        "近視",
        "遠視",
        "散光",
        "老花眼",
        "弱視",
        "斜視",
        "隱斜視"
    ],
    diseases_cn = [
        "正常",
        "近视",
        "远视",
        "散光",
        "老花眼",
        "弱视",
        "斜视",
        "隐斜视"
    ],
    diseases_jp = [
        "正常",
        "近視",
        "遠視",
        "乱視",
        "老眼",
        "弱視",
        "斜視",
        "斜位"
    ];

export const RIGHT_EYE = 0, LEFT_EYE = 1, BOTH_EYES = 2;



export function getLangJson(lang) {
    switch (lang) {
        case "ko":
            return ko;
        case "en":
            return en;
        case "cn":
            return cn;
        case "tw":
            return tw;
        case "jp":
            return jp;
    }

}

export function getFormatedDate(date = new Date(), omitDay = false, shortYear = false, separator = "-") {
    var day = date.getDate()
    day = day >= 10 ? day : "0" + day;

    var month = date.getMonth() + 1
    month = month >= 10 ? month : "0" + month;


    return (shortYear ? date.getFullYear().toString().substring(2, 4) : date.getFullYear()) + separator + month + (omitDay ? "" : separator + day);
}
export function getEngDate(date) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[date.getMonth()]
}
export function getDateFromString(date, separator = "-") {
    const dateParts = date.split(separator)
    if (dateParts.length < 3) return date;
    return new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2].split(' ')[0]))
}

export function getScoreDifference(othersScore, myScore) {
    const diff = othersScore - myScore;
    return diff > 0 ? '+ ' + diff : '- ' + Math.abs(diff);
}

export function getGender(gender, lang = "ko") {

    switch (lang) {
        case "en":
            return gender === 1 ? "M" : "F"
        case "cn":
            return gender === 1 ? "男" : "女"
        case "tw":
            return gender === 1 ? "男" : "女"
        case "jp":
            return gender === 1 ? "男性" : "女性"
        default:
            return gender === 1 ? "남" : "여"
    }
}

export function getGlasses(glasses, lang = "ko") {
    switch (lang) {
        case "en":
            return glasses === 1 ? "Wearing" : "Not wearing"
        case "cn":
            return glasses === 1 ? "佩戴" :  "不佩戴"
        case "tw":
            return glasses === 1  ? "配戴" : "不配戴"
        case "jp":
            return glasses === 1 ? "はい" : "いいえ"
        default:
            return glasses === 1 ? "착용" : "미착용"
    }

}

export function getDiseases(diseases, lang = "ko") {
    if (!diseases) return diseases;

    var diseasesStr = diseases_kr;
    switch (lang) {
        case "en":
            diseasesStr = diseases_en;
            break;
        case "cn":
            diseasesStr = diseases_cn;
            break;
        case "tw":
            diseasesStr = diseases_twn;
            break;
        case "jp":
            diseasesStr = diseases_jp;
            break;
    }

    const disArray = diseases.split(",");

    if (disArray.length <= 0) return diseases;

    if (!(/^\d+$/.test(disArray[0]))) return diseases;

    var _diseases = "";

    for (var i = 0; i < disArray.length; i++) {
        _diseases += diseasesStr[parseInt(disArray[i])] + (i < disArray.length - 1 ? ", " : "")
    }
    return _diseases;
}

export function getTimeOfCount(count, lang = "kr") {
    var minuteStr = "분";
    var hourStr = "시간 ";
    var timeSeparator = ""

    switch (lang) {
        case "en":
            minuteStr = " minute(s)";
            hourStr = " hour(s)";
            timeSeparator = " and "
            break;
        case "cn":
            minuteStr = "分钟。";
            hourStr = "小时";
            break;
        case 'tw':
            minuteStr = "分鐘。";
            hourStr = "小時";
            break;
        case 'jp':
            minuteStr = "分です。";
            hourStr = "時間";
            break;
    }

    const minutes = count * 3;
    if (minutes < 60) return minutes + minuteStr;
    const date = new Date();
    date.setHours(0, 0);
    date.setMinutes(minutes);

    return date.getHours() + hourStr + timeSeparator + date.getMinutes() + minuteStr;
}

export function getVABySides(data) {

    const right = []
    const left = []
    const dates = []
    if (!data || data.length === 0) return { right, left, dates }

    for (var i = data.length - 1; i > 0; i--) {
        right.push(data[i].rightVA)
        left.push(data[i].leftVA)
        dates.push(getFormatedDate(getDateFromString(data[i].date), false, true, "."))

        if (right.length >= 5) {
            break;
        }
    }

    const _data = {
        right: [data[0].rightVA, ...right.reverse()],
        left: [data[0].leftVA, ...left.reverse()],
        dates: [getFormatedDate(getDateFromString(data[0].date), false, true, "."), ...dates.reverse()]
    }
    return _data

}

export function getIntensityBySides(data) {

    const right = []
    const left = []
    const both = []
    const dates = []

    if (!data || data.length === 0) return { right, left, both, dates }


    for (var i = data.length - 1; i >= 0; i--) {

        const curr_date = data[i].date.split(" ")[0];
        var rightTime = 0;
        var leftTime = 0;
        var bothTime = 0;
        var right_count = 0, left_count = 0, both_count = 0;

        for (var j = i; j >= 0; j--) {

            if (data[j].date.split(" ")[0] === curr_date) {
                const results = JSON.parse(data[j].results);
                const recommended = results[results.length - 1];

                if (data[j].side === RIGHT_EYE) {

                    rightTime += ((recommended.ta + recommended.tb) / 20)
                    right_count++;

                } else if (data[j].side === LEFT_EYE) {

                    leftTime += ((recommended.ta + recommended.tb) / 20)
                    left_count++;

                } else {

                    bothTime += ((recommended.ta + recommended.tb) / 20)
                    both_count++;
                }

                if (j === 0) i = -1;
            } else {
                i = j > 0 ? j + 1 : -1;
                break;
            }
        }

        if (right_count == 0) right_count = 1;
        if (left_count == 0) left_count = 1;
        if (both_count == 0) both_count = 1;

        if (i <= 0 || dates.length < 5) {
            right.push(Math.round((rightTime / right_count) * 10) / 10)
            left.push(Math.round((leftTime / left_count) * 10) / 10)
            both.push(Math.round((bothTime / both_count) * 10) / 10)
            dates.push(getFormatedDate(getDateFromString(curr_date), false, true, "."))
        }
    }

    const _data = {
        right: right.reverse(),
        left: left.reverse(),
        both: both.reverse(),
        dates: dates.reverse()
    }
    return _data

}

export function getGameResultsByType(gameDatas) {
    var gazeScore = 0;
    var perceptionScore = 0;
    var focusScore = 0;
    var memoryScore = 0;
    var peripheralScore = 0;

    var gazeCount = 0;
    var perceptionCount = 0;
    var focusCount = 0;
    var memoryCount = 0;
    var peripheralCount = 0;


    for (var i = 0; i < gameDatas.length; i++) {
        for (var j = 0; j < gameDatas[i].length; j++) {
            const gd = gameDatas[i][j]

            if (getGameType(gd.gameId).includes(VISUAL_GAZE)) {
                gazeScore += gd.score;
                gazeCount++;
            }
            if (getGameType(gd.gameId).includes(VISUAL_PERCEPTION)) {
                perceptionScore += gd.score;
                perceptionCount++;
            }
            if (getGameType(gd.gameId).includes(VISUAL_FOCUS)) {
                focusScore += gd.score;
                focusCount++;
            }
            if (getGameType(gd.gameId).includes(VISUAL_MEMORY)) {
                memoryScore += gd.score;
                memoryCount++;
            }
            if (getGameType(gd.gameId).includes(PERIPHERAL_VISION)) {
                peripheralScore += gd.score;
                peripheralCount++;
            }
        }
    }
    gazeScore = getScoreAverage(gazeScore, gazeCount)
    perceptionScore = getScoreAverage(perceptionScore, perceptionCount)
    focusScore = getScoreAverage(focusScore, focusCount)
    memoryScore = getScoreAverage(memoryScore, memoryCount)
    peripheralScore = getScoreAverage(peripheralScore, peripheralCount)

    return { gazeScore, perceptionScore, focusScore, memoryScore, perceptionScore }
}

function getScoreAverage(score, count) {
    return count > 0 ? Math.round(score / count) : score
}

//const FOCUS = 1, PURSUIT = 2, VISUAL_MEMORY = 3, PERIPHERAL_VISION = 4, U_D_MVTS = 5;
const VISUAL_GAZE = 1, VISUAL_PERCEPTION = 2, VISUAL_FOCUS = 3, VISUAL_MEMORY = 4, PERIPHERAL_VISION = 5;

//시각주시력 시각인지력 시각집중력 시각기억력 주변시운동

function getGameType(gameId) {
    switch (gameId) {
        case 1:
            return [VISUAL_FOCUS, VISUAL_PERCEPTION];
        case 2:
            return [VISUAL_PERCEPTION, VISUAL_FOCUS];
        case 3:
            return [VISUAL_FOCUS, VISUAL_GAZE];
        case 4:
            return [VISUAL_MEMORY, VISUAL_FOCUS];
        case 5:
            return [VISUAL_GAZE, VISUAL_PERCEPTION];
        case 6:
            return [PERIPHERAL_VISION, VISUAL_MEMORY];
        case 7:
            return [VISUAL_PERCEPTION, VISUAL_GAZE];
        case 8:
            return [VISUAL_GAZE, PERIPHERAL_VISION];
        case 9:
            return [VISUAL_MEMORY, PERIPHERAL_VISION];
        case 10:
            return [PERIPHERAL_VISION, VISUAL_MEMORY];
    }
    return [];
}

export function getUsersAverageVA(data) {

    const _data = getVABySides(data)

    const rsum = _data.right.reduce((a, b) => a + b, 0);
    const right = Math.round(rsum / _data.right.length) || 0;

    const lsum = _data.left.reduce((a, b) => a + b, 0);
    const left = Math.round(lsum / _data.left.length) || 0;

    return { right, left }
}

export function getUsersAverageIntensity(data) {

    var rightTime = 0;
    var leftTime = 0;
    var bothTime = 0;

    var right_count = 0;
    var left_count = 0;
    var both_count = 0;

    for (var i = 0; i < data.length; i++) {

        const results = JSON.parse(data[i].results);
        const recommended = results[results.length - 1];

        if (data[i].side === RIGHT_EYE) {

            rightTime += ((recommended.ta + recommended.tb) / 20)
            right_count++;

        } else if (data[i].side === LEFT_EYE) {

            leftTime += ((recommended.ta + recommended.tb) / 20)
            left_count++;

        } else {

            bothTime += ((recommended.ta + recommended.tb) / 20)
            both_count++;
        }
    }

    if (right_count == 0) right_count = 1;
    if (left_count == 0) left_count = 1;
    if (both_count == 0) both_count = 1;

    const _data = {
        right: (Math.round((rightTime / right_count) * 10) / 10),
        left: (Math.round((leftTime / left_count) * 10) / 10),
        both: (Math.round((bothTime / both_count) * 10) / 10),
    }
    return _data;
}

export function getRanking(usersScore, myScore) {
    const rank = 100 - ((myScore / usersScore) * 100)

    return rank < 0 ? 1 : (rank > 99 ? 99 : rank)
}

export function getRankingIntensity(usersScore, myScore) {
    const rank = (myScore / usersScore) * 100

    return rank < 0 ? 1 : (rank > 99 ? 99 : rank)
}