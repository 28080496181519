import React, { Component } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const LineChart = ({ data, type }) => {
    var middle = ((Math.max(...data) - Math.min(...data)) / 2) + 1

    if(!data || data.length == 0) return(<></>)
    return (
        <div style={{ position: "absolute", display: "flex", flexDirection: "column", width: 50 * data.length, height: 40, marginLeft: 60, paddingRight: 20, paddingLeft: 20, }}>
            <div style={{ flexDirection: "row", display: "flex", width: 50 * data.length }}>
                {data.map((value, index) => {
                    return (
                        <div class="xValue" style={{color: index === 0 ? "#000" : "#1d8164"}}>{value == 0 ? "(-)" : value}</div>
                    )
                })}
            </div>
            <div style={{height:27}}>
                <Line
                    options={{
                        elements: {
                            line: {
                                borderWidth: 1,
                            },
                            point: {
                                borderWidth: 0,
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false
                            }
                        },
                        scales: {
                            x: {
                                grid: {
                                    display: false,
                                    drawBorder: false,
                                },
                                ticks: {
                                    display: false
                                }
                            },
                            y: {
                                grid: {
                                    display: false,
                                    drawBorder: false,
                                },
                                ticks: {
                                    display: false,
                                }
                            }
                        }
                    }}
                    data={{
                        labels: data.map((value) => (value.toString())),
                        datasets: [{
                            label: 'My First Dataset',
                            data: data,
                            fill: false,
                            tension: 0,
                            backgroundColor: data.map((value, index)=>( index === 0 ? "#000" : '#1d8164')),
                            borderColor: '#1d8164',

                        }]
                    }
                    }
                />
            </div>

        </div>
    )
}
const getPadding = (value) => {
    var padding = 0
    if (value < 9) {
        padding = 12
    }
    else if (value < 50) {
        padding = 8
    }
    else if (value < 100) {
        padding = 0
    }
    else if (value >= 100 && value < 150) {
        padding = 15
    }
    else if (value > 150) {
        padding = 9
    }
    return padding
}





export default LineChart;