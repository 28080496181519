import React, { Component, useState } from "react";
import { getLangJson } from "../utils/utils";
const BottomDetails = ({ lang = "ko" }) => {
    const langJson = getLangJson(lang)
    return (
        <div style={{ flexDirection: "column", flex: 0.2, display: "flex", marginTop: 20, backgroundColor: "#fafafa" }}>
            <div class="bottom">
                <div class="tHeader" style={{ width: "100%", fontSize: 10, height: 20, paddingLeft: 10, fontWeight:"bold" }}>· {langJson.a30}</div>
                {/* {langJson.a30} */}
                <div style={{ display: "flex", flexDirection: "column", paddingLeft: 10, paddingTop: 10, paddingRight: 10 }}>
                    <div class="bottomTitle" style={{fontSize: lang === 'jp' ? 11 : 12}}>. {langJson.a1}</div>
                    <div class="bottomText" style={{fontSize: lang === 'jp' ? 11 : 12}}>{langJson.a31}</div>
                    <div class="bottomTitle" style={{fontSize: lang === 'jp' ? 11 : 12}}>. {langJson.a17}</div>
                    <div class="bottomText" style={{fontSize: lang === 'jp' ? 11 : 12}}>{langJson.a32}</div>
                </div>
            </div>
            <div class="copyright">Copyright © 2022 (주)에덴룩스. All rights reserved.</div>
        </div>
    )
}
export default BottomDetails;