import React, { Component, useState } from "react";
// import ReactToPdf from "react-to-pdf";
import ReactToPdf from "../components/ReacToPdf";
import $ from 'jquery';

import * as ServiceUser from "../service/ServiceUser";
import logo from "../images/edenlux_logo.png";
import { MdTimer } from "react-icons/md"
import { getFormatedDate, getGender, getGlasses, getDiseases, getTimeOfCount, getVABySides, getIntensityBySides, getGameResultsByType, getUsersAverageVA, getUsersAverageIntensity, getEngDate, getRanking } from "../utils/utils";
import DataTable from "../components/DataTable";
import GameResults from "../components/GameResults";
import BottomDetails from "../components/BottomDetails";
import useFitText from "use-fit-text";

const ref = React.createRef();

const options = {
    orientation: 'portrait',
    unit: 'mm',
    format: [420, 594]
};
var refs = [];

function fileName(uid, id, lang, format, date) {
    id = id.split('/')[0]
    return uid + id + "_report_" + date + "_" + lang + "." + format
}


class Pages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userInfo: null,
            gameCount: {
                count: 0
            },
            eyeScore: {
                rank: 0,
                myScore: null,
                usersScore: null
            },
            eyeReactTime: {
                rank: 0,
                myScore: null,
                usersScore: null,
            },
            gameResults: null,
        }

        this.innerConstructor();

    }


    innerConstructor() {
        const { uid, location, token } = this.props.match.params;

        console.log("token", token)
        ServiceUser.setBackServer(8080);
        // if (location.includes("kr")) {
        //     ServiceUser.setBackServer(8080);
        // } else if (location.includes("jpn")) {
        //     ServiceUser.setBackServer(8085);
        // } else if (location.includes("twn")) {
        //     ServiceUser.setBackServer(8087);
        // } else if (location.includes("chn")) {
        //     ServiceUser.setBackServer(8088);
        // } else if (location.includes("us")) {
        //     ServiceUser.setBackServer(8082);
        // } else {
        //     ServiceUser.setBackServer(8080);
        // }

        ServiceUser.svcGetUserInfo(uid, token).then((data) => {
            this.setState({ userInfo: data })
        })
        ServiceUser.svcGetThiMonthGameCount(uid, token).then((data) => {
            this.setState({ gameCount: data })
        })
        ServiceUser.svcGetVA(uid, token).then((data) => {

            const myScore = getVABySides(data)

            this.setState({ eyeScore: { ...this.state.eyeScore, ...{ myScore: myScore } } })

            ServiceUser.svcGetUsersVA(token).then((data) => {
                const uav = getUsersAverageVA(data)

                const r = myScore.right.length > 0 ? Math.max(...myScore.right) : 0
                const l = myScore.left.length > 0 ? Math.max(...myScore.left) : 0

                const rightRank = getRanking(uav.right, r)
                const leftRank = getRanking(uav.left, l)

                const rank = Math.round((rightRank + leftRank) / 2)

                this.setState({ eyeScore: { ...this.state.eyeScore, ...{ usersScore: uav, rank: rank } } })
            })

        })
        ServiceUser.svcGetIntensity(uid, token).then((data) => {

            const myScore = getIntensityBySides(data)
            this.setState({ eyeReactTime: { ...this.state.eyeReactTime, ...{ myScore: myScore } } })

            ServiceUser.svcGetUsersIntensity(token).then((_data) => {
                const uai = getUsersAverageIntensity(_data)

                const r = myScore.right.length > 0 ? Math.max(...myScore.right) : 0
                const l = myScore.left.length > 0 ? Math.max(...myScore.left) : 0
                const b = myScore.both.length > 0 ? Math.max(...myScore.both) : 0

                const rightRank = getRanking(uai.right, r)
                const leftRank = getRanking(uai.left, l)
                const bothRank = getRanking(uai.both, b)

                const rank = 100 - Math.round((rightRank + leftRank + bothRank) / 3)

                this.setState({ eyeReactTime: { ...this.state.eyeReactTime, ...{ usersScore: uai, rank: rank } } })
            })
        })



        var gameData = []
        for (var gameId = 1; gameId <= 10; gameId++) {
            ServiceUser.svcGetGameData(uid, gameId, token).then((data) => {
                gameData = [...gameData, data]
                if (gameData.length >= 10) {
                    this.setState({ gameResults: getGameResultsByType(gameData) })

                }
            })
        }

    }

    onFileDownloaded() {
        setTimeout(() => {
            window.close();
        }, 1000);
    }

    componentDidMount() {



    }

    render() {
        console.log("rendering")
        if (!this.state.userInfo ||
            this.state.eyeScore.myScore === null ||
            this.state.eyeScore.usersScore === null ||
            this.state.gameResults === null ||
            this.state.eyeReactTime.myScore === null ||
            this.state.eyeReactTime.usersScore === null

        ) {
            return <div />
        };
        // setTimeout(() => {
        //     $(".downloadBtn").trigger("click");
        //     console.log("supposed to download")
        // }, 1500);
        //refs[1] = ref1; refs[2] = ref2; refs[3] = ref3;
        //fileName(this.state.user, this.state.userInfo.id, this.state.lang)
        const { lang, fileType, date, location } = this.props.match.params;

        return (
            <div class="scope">
                <ReactToPdf targetRef={ref} filename={fileName(this.state.userInfo.uid, this.state.userInfo.id, lang, fileType, date)} x={0} y={-1} options={options} onComplete={this.onFileDownloaded} fileType={this.props.match.params.fileType}>
                    {({ drawNaddToPdf }) => (<a class="downloadBtn" onClick={drawNaddToPdf}></a>)}
                </ReactToPdf>

                <div ref={ref} >
                    <div class="page1 page hide-scrollbar" >
                        <img src={logo} style={{ alignSelf: 'flex-end', height: 33, width: 'auto' }} />
                        {lang === "ko" && <UserInfoKR userInfo={this.state.userInfo} gameCount={this.state.gameCount} />}
                        {lang === "en" && <UserInfoEN userInfo={this.state.userInfo} gameCount={this.state.gameCount} />}
                        {lang === "cn" && <UserInfoCN userInfo={this.state.userInfo} gameCount={this.state.gameCount} />}
                        {lang === "tw" && <UserInfoTW userInfo={this.state.userInfo} gameCount={this.state.gameCount} />}
                        {lang === "jp" && <UserInfoJP userInfo={this.state.userInfo} gameCount={this.state.gameCount} />}
                        <div class="midSection">
                            <div class="midRow" >
                                
                                <DataTable type={1} datas={this.state.eyeScore} lang={lang} location={location} />
                                <DataTable type={2} datas={this.state.eyeReactTime} lang={lang} />
                                <GameResults data={this.state.gameResults} lang={lang} />

                                <BottomDetails lang={lang} />
                            </div>


                        </div >
                    </div>
                </div>

            </div>
        );
    }
}

const UserInfoKR = ({ userInfo, gameCount }) => {
    const { fontSize, ref } = useFitText();
    return (
        <>
            <div class="infoRow">
                <h5 class="label" style={{ width: 90 }}>닉네임</h5>
                <p class="infoText" style={{ flex: 1 }}>{userInfo.Nickname}</p>
                <h5 class="label">성별</h5>
                <p class="infoText">{getGender(userInfo.Sex)}</p>
                <h5 class="label">나이</h5>
                <p class="infoText">{new Date().getFullYear() - parseInt((userInfo.Birth).toString().substring(0, 4)) + 1}</p>
                <h5 class="label">안경 착용 여부</h5>
                <p class="infoText">{getGlasses(userInfo.Glass)}</p>
            </div>

            <div class="infoRow" style={{ borderTopWidth: 0, borderBottomColor: "#1d8164" }}>
                <h5 class="label" style={{ width: 90 }}>눈 상턔</h5>
                <p class="infoText" style={{ flex: 1, fontSize, height: 55 }} ref={ref}>{getDiseases(userInfo.Disease)}</p>
                <h5 class="label">사용기간</h5>
                <p class="infoText">{userInfo.CreateDate.split(" ")[0] + " - " + getFormatedDate(new Date())}</p>
            </div>
            <div style={{ paddingTop: 12, paddingBottom: 12, display: 'flex', flexDirection: 'row', width: "100%", justifyContent: "center" }}>
                <p class="infoText" style={{ paddingTop: 5, paddingBottom: 5, lineHeight: 1.5, fontSize: 14 }}>
                    <MdTimer color={"#1d8164"} size={20} style={{ marginRight: 5 }} />
                    <b>{userInfo.Nickname}</b>
                    {" 님의 "}
                    <b>{(new Date().getMonth() + 1) + "월 "}</b>
                    {"눈 운동 시간은 "}
                    <span style={{ color: "#1d8164" }}><b>총 {getTimeOfCount(gameCount.count)}</b> </span> 입니다.
                        </p>
            </div>
        </>
    )
}

const UserInfoEN = ({ userInfo, gameCount }) => {
    const { fontSize, ref } = useFitText();
    return (
        <>
            <div class="infoRow">
                <h5 class="label" style={{ width: 90, paddingLeft: 12, }}>Nickname</h5>
                <p class="infoText" style={{ flex: 1 }}>{userInfo.Nickname}</p>
                <h5 class="label">Gender</h5>
                <p class="infoText">{getGender(userInfo.Sex, "en")}</p>
                <h5 class="label">Age</h5>
                <p class="infoText">{new Date().getFullYear() - parseInt((userInfo.Birth).toString().substring(0, 4)) + 1}</p>
                <h5 class="label">Glasses</h5>
                <p class="infoText">{getGlasses(userInfo.Glass, "en")}</p>
            </div>

            <div class="infoRow" style={{ borderTopWidth: 0, borderBottomColor: "#1d8164" }}>
                <h5 class="label" style={{ width: 90, height: 55, paddingLeft: 15, paddingTop: 9 }}>Eye condition</h5>
                <p class="infoText" style={{ flex: 1, fontSize, height: 55 }} ref={ref}>{getDiseases(userInfo.Disease, "en")}</p>
                <h5 class="label">Period of use</h5>
                <p class="infoText">{userInfo.CreateDate.split(" ")[0] + " - " + getFormatedDate(new Date())}</p>
            </div>
            <div style={{ paddingTop: 12, paddingBottom: 12, display: 'flex', flexDirection: 'row', width: "100%", justifyContent: "center" }}>
                <p class="infoText" style={{ paddingTop: 5, paddingBottom: 5, lineHeight: 1.5, fontSize: 14 }}>
                    <MdTimer color={"#1d8164"} size={20} style={{ marginRight: 5 }} />
                    <b>{userInfo.Nickname}</b>
                    {", your eye training time in "}
                    <b>{getEngDate(new Date())}</b>
                    {" is in "}
                    <span style={{ color: "#1d8164" }}><b>total {getTimeOfCount(gameCount.count, "en")}</b> </span>.
            </p>
            </div>
        </>
    )
}

const UserInfoCN = ({ userInfo, gameCount }) => {
    const { fontSize, ref } = useFitText();
    return (
        <>
            <div class="infoRow">
                <h5 class="label" style={{ width: 90 }}>昵称</h5>
                <p class="infoText" style={{ flex: 1 }}>{userInfo.Nickname}</p>
                <h5 class="label">性别</h5>
                <p class="infoText">{getGender(userInfo.Sex, 'cn')}</p>
                <h5 class="label">年龄</h5>
                <p class="infoText">{new Date().getFullYear() - parseInt((userInfo.Birth).toString().substring(0, 4)) + 1}</p>
                <h5 class="label">是否佩戴眼镜</h5>
                <p class="infoText">{getGlasses(userInfo.Glass, 'cn')}</p>
            </div>

            <div class="infoRow" style={{ borderTopWidth: 0, borderBottomColor: "#1d8164" }}>
                <h5 class="label" style={{ width: 90 }}>眼健康</h5>
                <p class="infoText" style={{ flex: 1, fontSize, height: 55 }} ref={ref}>{getDiseases(userInfo.Disease, 'cn')}</p>
                <h5 class="label">使用期间</h5>
                <p class="infoText">{userInfo.CreateDate.split(" ")[0] + " - " + getFormatedDate(new Date())}</p>
            </div>
            <div style={{ paddingTop: 12, paddingBottom: 12, display: 'flex', flexDirection: 'row', width: "100%", justifyContent: "center" }}>
                <p class="infoText" style={{ paddingTop: 5, paddingBottom: 5, lineHeight: 1.5, fontSize: 14 }}>
                    <MdTimer color={"#1d8164"} size={20} style={{ marginRight: 5 }} />
                    <b>{userInfo.Nickname}</b>
                    {", "}
                    <b>{(new Date().getMonth() + 1) + "月份你的"}</b>
                    {"训练总时间为"}
                    <span style={{ color: "#1d8164" }}><b>{getTimeOfCount(gameCount.count, 'cn')}</b> </span>
                </p>
            </div>
        </>
    )
}

const UserInfoTW = ({ userInfo, gameCount }) => {
    const { fontSize, ref } = useFitText();
    return (
        <>
            <div class="infoRow">
                <h5 class="label" style={{ width: 90 }}>暱稱</h5>
                <p class="infoText" style={{ flex: 1 }}>{userInfo.Nickname}</p>
                <h5 class="label">性別</h5>
                <p class="infoText">{getGender(userInfo.Sex, 'tw')}</p>
                <h5 class="label">年齡</h5>
                <p class="infoText">{new Date().getFullYear() - parseInt((userInfo.Birth).toString().substring(0, 4)) + 1}</p>
                <h5 class="label">是否佩戴眼鏡</h5>
                <p class="infoText">{getGlasses(userInfo.Glass, 'tw')}</p>
            </div>

            <div class="infoRow" style={{ borderTopWidth: 0, borderBottomColor: "#1d8164" }}>
                <h5 class="label" style={{ width: 90 }}>眼健康</h5>
                <p class="infoText" style={{ flex: 1, fontSize, height: 55 }} ref={ref}>{getDiseases(userInfo.Disease, 'tw')}</p>
                <h5 class="label">使用期間</h5>
                <p class="infoText">{userInfo.CreateDate.split(" ")[0] + " - " + getFormatedDate(new Date())}</p>
            </div>
            <div style={{ paddingTop: 12, paddingBottom: 12, display: 'flex', flexDirection: 'row', width: "100%", justifyContent: "center" }}>
                <p class="infoText" style={{ paddingTop: 5, paddingBottom: 5, lineHeight: 1.5, fontSize: 14 }}>
                    <MdTimer color={"#1d8164"} size={20} style={{ marginRight: 5 }} />
                    <b>{userInfo.Nickname}</b>
                    {", "}
                    <b>{(new Date().getMonth() + 1) + "月份你的"}</b>
                    {"練總時間爲"}
                    <span style={{ color: "#1d8164" }}><b> {getTimeOfCount(gameCount.count, 'tw')}</b> </span>
                </p>
            </div>
        </>
    )
}

const UserInfoJP = ({ userInfo, gameCount }) => {
    const { fontSize, ref } = useFitText();
    return (
        <>
            <div class="infoRow">
                <h5 class="label" style={{ width: 90, padding: '19px 5px' }}>ニックネーム</h5>
                <p class="infoText" style={{ flex: 1 }}>{userInfo.Nickname}</p>
                <h5 class="label">性別</h5>
                <p class="infoText">{getGender(userInfo.Sex, 'jp')}</p>
                <h5 class="label">年齢</h5>
                <p class="infoText">{new Date().getFullYear() - parseInt((userInfo.Birth).toString().substring(0, 4)) + 1}</p>
                <h5 class="label">眼鏡着用有無</h5>
                <p class="infoText">{getGlasses(userInfo.Glass, 'jp')}</p>
            </div>

            <div class="infoRow" style={{ borderTopWidth: 0, borderBottomColor: "#1d8164" }}>
                <h5 class="label" style={{ width: 90, padding: '19px 5px' }}>目の状態</h5>
                <p class="infoText" style={{ flex: 1, fontSize, height: 55 }} ref={ref}>{getDiseases(userInfo.Disease, 'jp')}</p>
                <h5 class="label">使用期間</h5>
                <p class="infoText">{userInfo.CreateDate.split(" ")[0] + " - " + getFormatedDate(new Date())}</p>
            </div>
            <div style={{ paddingTop: 12, paddingBottom: 12, display: 'flex', flexDirection: 'row', width: "100%", justifyContent: "center" }}>
                <p class="infoText" style={{ paddingTop: 5, paddingBottom: 5, lineHeight: 1.5, fontSize: 14 }}>
                    <MdTimer color={"#1d8164"} size={20} style={{ marginRight: 5 }} />
                    <b>{userInfo.Nickname}</b>
                    {"マさん"}
                    <b>{(new Date().getMonth() + 1) + "月"}</b>
                    {"の目の運動時間は、"}
                    <span style={{ color: "#1d8164" }}><b>計{getTimeOfCount(gameCount.count, 'jp')}</b> </span>
                </p>
            </div>
        </>
    )
}


export default Pages;
