const cn = {
    a1: "视力分数",
    a2: "后",
    a2_: "只超过奥图视",
    a3: "前",
    a3_: "超越了",
    a4: "你的视力分数",
    a4_: "你的调节反应速度",
    a5: "的奥图视用户",
    a6: "用户平均分数",
    a7: "我的最近分数",
    a8: "累计数据",
    a9: "单位",
    a10: "分",
    a101: "分",
    a11: "右眼",
    a12: "左眼",
    a13: "双眼" ,
    a14: "最初",
    a15: "最近",
    a16: "次",
    a17: "调节反应速度",
    a18: "用户平均速度",
    a19: "我的最近速度",
    a20: "秒",
    a21: "游戏训练结果",
    a22: "标准以下",
    a23: "标准",
    a24: "标准以上",
    a25: "视觉注視力",
    a26: "视觉认知",
    a27: "视觉集中力",
    a28: "视觉记忆力",
    a29: "周边注视运动",
    a30: "前90%是指，100名奥图视用户中排名第90名。数值越小越靠前。",
    a31: "视力分数是用奥图视APP对用户的视力进行评估的数值。最高分数为200分，最低为10分。分数越高，代表眼睛越健康。\n评分仅供参考，最准确的视力状况，还请前往医院进行检查。",
    a32: "调节反应速度是，眼睛看远之后能很快看清楚近处，看近之后能很快看清楚远处的调节能力也就是聚焦能力的测定数值，单位为“秒”。调节反应也是评价眼睛是否能够平稳有效地改变调节量的指标。测定数值越小，表示调节反应越快，眼睛越健康。调节反应速度是在测定训练强度时被测定。",
    a33: "前90%是指，100名奥图视用户中排名第90名。数值越小越靠前。"
}
export default cn;