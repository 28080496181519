import React, { Component } from "react";
import { GoTriangleDown } from "react-icons/go"
import LineChart from "./LineChart";
import { getLangJson } from "../utils/utils";


const DataTable = ({ type, datas, lang = "kr", location }) => {
    const langJson = getLangJson(lang)
    var unit = type == 1 ? langJson.a101 : langJson.a20
    location = ''

    console.log("datas", datas)
    return (
        <div style={{ flexDirection: "column", flex: 0.2, display: "flex", marginBottom: 10 }}>
            <div class="header">
                <div class="headerTitle">{type === 1 ? langJson.a1 : langJson.a17}</div>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                padding: 10,
                paddingLeft: 15,
                paddingRight: 15,
                marginTop: 1,
                marginBottom: 1,
                borderTop: "1px solid #c9c9c9",
                borderBottom: "1px solid #c9c9c9"
            }}>
                <div style={{
                    color: "#666666",
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                    marginRight: 20,
                    marginRight: 25
                }}>
                    <span style={{
                        fontSize: 12,
                        marginTop: 5,
                        marginRight: 3
                    }}>
                        {langJson.a3}
                    </span>
                    <div style={{
                        fontWeight: "bold",
                        fontSize: 25,
                        color: "#000"
                    }}>{datas.rank}
                    </div>
                    <div style={{
                        fontSize: 12,
                        color: "#000",
                        paddingTop: 10
                    }}>%
                    </div>
                </div>
                <div style={{ marginLeft: 10, paddingTop: 15, width: "575px", display: "flex", flexDirection: "column" }}>
                    <div class="percentBar"></div>
                    <div style={{ position: "absolute", display: "flex", height: 8, width: 575 }}>
                        <div style={{ height: 8, marginLeft: (100 - datas.rank) / 100 * 575, width: 3, backgroundColor: "#fff" }}>
                            <div style={{ width: 14.14, height: 7, overflow: "hidden", marginTop: -10, marginLeft: -6 }}>
                                <div style={{ width: 10, height: 10, transform: 'rotate(45deg)', backgroundColor: "#000", marginTop: -5, marginLeft: 2 }} />
                                {/* <GoTriangleDown color={"#000"} style={{ marginTop: -40, marginLeft: -6 }} size={15} /> */}
                            </div>
                        </div>
                    </div>
                    <div style={{ fontSize: 11 }}>
                        {type === 1 ? langJson.a4 : langJson.a4_}<b>{langJson.a3_}{!'cntw'.includes(lang) ? datas.rank : 100 - datas.rank}%</b>{langJson.a5}
                    </div>
                </div>

            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginRight: 1 }}>
                    <div class="tHeader">
                        <div class="thText grayTable noBorderLeft" style={{ width: 60 }}></div>
                        <div class="thText grayTable" style={{ width: "140px" }}>{type === 1 ? langJson.a6 : langJson.a18}</div>
                        <div class="thText grayTable noBorderRight" style={{ color: "#1d8164", backgroundColor: "#daf1ea", width: "170px" }}>{type == 1 ? langJson.a7 : langJson.a19}</div>
                    </div>
                    {type > 1 && <div class="tRow">
                        <div class="thText grayTable tColumn noBorderLeft">{langJson.a13}</div>
                        <div class="thText grayTable tOldValue">{datas.usersScore.both}{unit}</div>
                        <div class="thText grayTable tNewValue noBorderRight" >
                            <span class="tNewValueText">{datas.myScore.both.length > 0 ? datas.myScore.both[datas.myScore.both.length - 1] : '0'}{unit}</span>
                        </div>
                    </div>}
                    <div class="tRow">
                        <div class="thText grayTable tColumn noBorderLeft">{langJson.a11}</div>
                        <div class="thText grayTable tOldValue">{location == 'chn' ? datas.usersScore.right / 2 : datas.usersScore.right}{unit}</div>
                        <div class="thText grayTable tNewValue noBorderRight" >
                            <span class="tNewValueText">{datas.myScore.right.length > 0 ? (location == 'chn' ? (datas.myScore.right[datas.myScore.right.length - 1]) / 2 : datas.myScore.right[datas.myScore.right.length - 1]) : '0'}{unit}</span>
                            {type == 1 && <span class="tNewValueSmallText">{location == 'chn' ? "/100" : "/200"}{langJson.a101}</span>}</div>
                    </div>
                    <div class="tRow">
                        <div class="thText grayTable tColumn noBorderLeft">{langJson.a12}</div>
                        <div class="thText grayTable tOldValue">{location == 'chn' ? datas.usersScore.left / 2 : datas.usersScore.left}{unit}</div>
                        <div class="thText grayTable  tNewValue noBorderRight" >
                            <span class="tNewValueText">{datas.myScore.left.length > 0 ? (location == 'chn' ? (datas.myScore.left[datas.myScore.left.length - 1]) / 2 : datas.myScore.left[datas.myScore.left.length - 1]) : '0'}{unit}</span>
                            {type == 1 && <span class="tNewValueSmallText">{location == 'chn' ? "/100" : "/200"}{langJson.a101}</span>}
                        </div>
                    </div>
                </div>

                <div style={{ marginLeft: 1 }}>
                    <div class="tHeader">
                        <div class="thText grayTable  noBorderLeft  noBorderRight" style={{ width: "100%", display: "flex" }}><div style={{ width: "85%" }}>{langJson.a8}</div>
                            <div style={{ fontWeight: "normal", alignSelf: "flex-end", width: 100, border: 0 }}>{langJson.a9}:{type == 1 ? langJson.a10 : langJson.a20} </div>
                        </div>
                    </div>
                    {type > 1 && <div style={{ display: "flex", flexDirection: "column" }}>
                        <div class="tRow">
                            <div class="thText grayTable tColumn noBorderLeft">{langJson.a13}</div>
                            <div class="thText box firstData" ></div>
                            <div class="thText box" ></div>
                            <div class="thText box" ></div>
                            <div class="thText box" ></div>
                            <div class="thText box" ></div>
                            <div class="thText box noBorderRight" ></div>
                        </div>
                        <LineChart data={datas.myScore.both} type={type} />
                    </div>}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div class="tRow">
                            <div class="thText grayTable tColumn noBorderLeft">{langJson.a11}</div>
                            <div class="thText box firstData" ></div>
                            <div class="thText box" ></div>
                            <div class="thText box" ></div>
                            <div class="thText box" ></div>
                            <div class="thText box" ></div>
                            <div class="thText box noBorderRight" ></div>
                        </div>
                        <LineChart data={datas.myScore.right} type={type} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div class="tRow">
                            <div class="thText grayTable tColumn noBorderLeft">{langJson.a12}</div>
                            <div class="thText box firstData" ></div>
                            <div class="thText box" ></div>
                            <div class="thText box" ></div>
                            <div class="thText box" ></div>
                            <div class="thText box" ></div>
                            <div class="thText box noBorderRight" ></div>
                        </div>
                        <LineChart data={datas.myScore.left} type={type} />
                    </div>
                    <div class="tRow" style={{ height: 20 }}>
                        <div class="thText tColumn staticsDate" style={{ fontSize: lang === 'jp' ? 8 : 10 }}>{langJson.a14}<div style={{ color: "#666666" }}>/{langJson.a15}5{langJson.a16}</div></div>
                        {[0, 1, 2, 3, 4, 5].map((value, index) => {
                            return (
                                <div class="thText box staticsDate" style={{ color: index === 0 ? "#000" : "#666666" }}>{value < datas.myScore.dates.length ? datas.myScore.dates[value] : "(-)"}</div>
                            )
                        })}
                    </div>
                </div>
            </div>

        </div >
    )
}
export default DataTable;