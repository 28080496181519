import React, { Component, useState } from "react";
import { getLangJson } from "../utils/utils";

const GameResults = ({ data, lang = "ko" }) => {
    const [dataArray, setDataArray] = useState([data.gazeScore, data.perceptionScore, data.focusScore, data.memoryScore, data.perceptionScore])
    const langJson = getLangJson(lang)

    return (

        <div style={{ flexDirection: "column", flex: 0.2, display: "flex", marginBottom: 10 }}>
            <div class="header">
                <div class="headerTitle">{langJson.a21}</div>
            </div>
            <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
                <div class="tHeader" style={{ width: "100%", height: 25 }}>
                    <div class="thText grayTable" style={{ width: "50%", borderLeft: 0 }}>{langJson.a22}</div>
                    <div class="thText grayTable" style={{ width: "15%" }}>{langJson.a23}</div>
                    <div class="thText grayTable" style={{ width: "35%", borderRight: 0 }}>{langJson.a24}</div>
                </div>
                <div class="gameResultsRow">
                    <div class="leftColumn gameResultsText">
                        <div style={{ width: 145, textAlign: lang === 'en' ? 'right' : 'center' }}>{langJson.a25}
                        </div>
                    </div>
                    <div class="midleColumn "></div>
                    <div class="rightColumn "></div>
                </div>
                <div class="gameResultsRow">
                    <div class="leftColumn gameResultsText">
                        <div style={{ width: 145, textAlign: lang === 'en' ? 'right' : 'center' }}>{langJson.a26}
                        </div>
                    </div>
                    <div class="midleColumn "></div>
                    <div class="rightColumn "></div>
                </div>
                <div class="gameResultsRow">
                    <div class="leftColumn gameResultsText">
                        <div style={{ width: 145, textAlign: lang === 'en' ? 'right' : 'center' }}>{langJson.a27}
                        </div>
                    </div>
                    <div class="midleColumn "></div>
                    <div class="rightColumn "></div>
                </div>
                <div class="gameResultsRow">
                    <div class="leftColumn gameResultsText ">
                        <div style={{ width: 145, textAlign: lang === 'en' ? 'right' : 'center' }}>{langJson.a28}
                        </div>
                    </div>
                    <div class="midleColumn "></div>
                    <div class="rightColumn "></div>
                </div>
                <div class="gameResultsRow">
                    <div class="leftColumn gameResultsText">
                        <div style={{ width: 145, textAlign: lang === 'en' ? 'right' : 'center', lineHeight:1, fontSize: lang == 'en' ? 11 : 'inherit' }}>{langJson.a29}
                        </div>
                    </div>
                    <div class="midleColumn "></div>
                    <div class="rightColumn "></div>
                </div>
            </div>
            <div style={{ display: "flex", position: "absolute", width: 732, flexDirection: "column" }}>
                <div style={{ height: 28, width: "100%" }}></div>
                <div style={{ width: "100%", height: 25 }}></div>
                {dataArray.map((value) => {
                    return (
                        <div class="gameResultsRow">
                            <div class="gameResultsChartText"></div>
                            <div class="gameResultsChart ">
                                <div class="gameResultBarParent" style={{ width: value <= 0 ? 0 : ((value / 100 * 557) - 5) }}>
                                    <div class="gameResultsBar"></div>
                                </div>
                                <div class="gameResultsPercent">{value}</div>
                            </div>
                        </div>
                    )
                })}
                <div class="gameResultsRow">
                    <div class="gameResultsChartText"></div>
                    <div class="gameResultsChart " style={{ paddingTop: 0 }}>
                        <div class="test" style={{ display: "flex" }}>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => {
                                return (
                                    <div style={{ width: 10 / 100 * 557, fontWeight: "normal", fontSize: 12, color: "#8e918f", paddingLeft: 50 }}>{value * 10}</div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default GameResults;