import React from 'react';
import {Route, BrowserRouter as Router} from "react-router-dom";

import Pages from "./screens/Pages";


function App() {
  return (
    <Router>
      <main>
        <Route path='/:uid/:lang/:location/:fileType/:date/:token' component={Pages} />
      </main>
    </Router>
  );
}

export default App;
